import React from "react";

import Contacts from "../components/contacts";
import Header from "../components/header";

import misionIcon from "../resources/mission.png";
import visionIcon from "../resources/vision.png";
import valuesIcon from "../resources/values.png";

// import aboutusLogo from "../resources/aboutusLogo.png";

function AboutUsRoute(props) {
  const values = {
    Innovación:
      "Compromiso de estar siempre a la vanguardia, desarrollando productos de tendencia que satisfagan las necesidades actuales de nuestros clientes y consumidores.",
    Calidad:
      "Garantizamos la calidad del servicio y producto final, empleando tecnología avanzada, insumos de alta gama, cumplimiento regulatorio y control riguroso de procesos en cada etapa de la producción, garantizando productos seguros, eficaces y de alto rendimiento.",
    Sostenibilidad:
      "Promovemos prácticas responsables y sostenibles en todas nuestras operaciones, minimizando nuestro impacto ambiental, a través del uso de materias primas y materiales eco amigables.",
    Inclusión:
      "Creamos productos que se adaptan a las necesidades de las personas de todas las edades, géneros y etnias porque creemos en la belleza de la diversidad.",
    "Ética y transparencia":
      "Actuamos con integridad y transparencia en todas nuestras acciones.",
    "Responsabilidad social":
      "Estamos comprometidos con el bienestar de nuestros empleados, proveedores y comunidad en general, respetando sus derechos y contribuyendo a un futuro más justo y equitativo.",
    "Compromiso con el cliente":
      "Está orientado a crear experiencias positivas, construir confianza y fomentar lealtad a través de servirles con productos y servicios con altos estándares de calidad.",
  };

  const renderValues = Object.keys(values).map((value) => {
    return (
      <li style={{ padding: "10px 0px", textAlign: "justify" }}>
        <strong>{value}:</strong> {values[value]}
      </li>
    );
  });

  return (
    <React.Fragment>
      <div style={{ height: "100%" }}>
        <Contacts />
        <Header selected={4} />
        {/* mision */}
        <div
          className="content mobile_reverse"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingLeft: "40px",
            paddingRight: "40px",
            // border: "2px solid red",
          }}
        >
          {/* icon */}

          <div style={{ textAlign: "justify", padding: "20px" }}>
            <p>
              Revolucionar la industria de la belleza a través de la innovación
              continua, calidad y compromiso con el éxito de nuestros clientes,
              combinando ciencia, tecnología y naturaleza, posicionando la marca
              de confianza elegida por nuestros clientes con productos y
              servicios que marquen la diferencia.
            </p>
          </div>
          <div>
            <div className="title">Misión</div>
            <img src={misionIcon} alt="misión" width={"200px"} />
          </div>
        </div>
        {/* visión */}
        <div
          className="content"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingLeft: "40px",
            paddingRight: "40px",
            // border: "2px solid red",
          }}
        >
          {/* icon */}
          <div>
            <div className="title">Visión</div>
            <img src={visionIcon} alt="visión" width={"200px"} />
          </div>
          <div style={{ textAlign: "justify", padding: "20px" }}>
            <p>
              Ser reconocidos como la empresa líder en la innovación y
              desarrollo de productos cosméticos, dermocosméticos y servicios de
              maquila a nivel global, priorizando el compromiso con la
              excelencia, la calidad, la sostenibilidad y el bienestar de
              nuestros clientes.
            </p>
          </div>
        </div>
        {/* valores */}
        <div
          className="content"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingLeft: "40px",
            paddingRight: "40px",
            // border: "2px solid red",
          }}
        >
          {/* icon */}
          <div>
            <div className="title">Valores</div>
            <img src={valuesIcon} alt="valores" width={"200px"} />
          </div>
          <div style={{ textAlign: "justify", padding: "20px" }}>
            {/* <ul>
              <li>
                <strong>Innovación:</strong> compromiso de estar siempre a la
                vanguardia, desarrollando productos de tendencia que satisfagan
                las necesidades actuales de nuestros clientes y consumidores.
              </li>
              <li>
                <strong>Calidad:</strong> garantizamos la calidad del servicio y
                producto final, empleando tecnología avanzada, insumos de alta
                gama, cumplimiento regulatorio y control riguroso de procesos en
                cada etapa de la producción, garantizando productos seguros,
                eficaces y de alto rendimiento.
              </li>
              <li>
                <strong>Sostenibilidad:</strong> promovemos prácticas
                responsables y sostenibles en todas nuestras operaciones,
                minimizando nuestro impacto ambiental, a través del uso de
                materias primas y materiales eco amigables.
              </li>
              <li>
                <strong>Inclusión:</strong> creamos productos que se adaptan a
                las necesidades de las personas de todas las edades, géneros y
                etnias porque creemos en la belleza de la diversidad.
              </li>
              <li>
                <strong>Ética y transparencia:</strong> actuamos con integridad
                y transparencia en todas nuestras acciones.
              </li>
              <li>
                <strong>Responsabilidad social:</strong> estamos comprometidos
                con el bienestar de nuestros empleados, proveedores y comunidad
                en general, respetando sus derechos y contribuyendo a un futuro
                más justo y equitativo.
              </li>
              <li>
                <strong>Compromiso con el cliente:</strong> está orientado a
                crear experiencias positivas, construir confianza y fomentar
                lealtad a través de servirles con productos y servicios con
                altos estándares de calidad.
              </li>
            </ul> */}
            <ul>{renderValues}</ul>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default AboutUsRoute;
