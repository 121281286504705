import React from "react";

import whatsAppIcon from "../resources/whatsAppIcon.png";

function FloatingButton(props) {
  return (
    <React.Fragment>
      <div className="floatingBtn_container">
        <a href={props.link} target="_blank" rel="noopener noreferrer">
          <img
            src={props.icon || whatsAppIcon}
            alt="FloatingButtonIcon"
            width={"65px"}
          />
        </a>
      </div>
    </React.Fragment>
  );
}

export default FloatingButton;
