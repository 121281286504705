import React from "react";

import { useContext, createContext } from "react";

export const backendContext = createContext();

export const useBackend = () => {
  const context = useContext(backendContext);
  return context;
};

export function BackendProvider({ children }) {
  // const backendBaseURL = "http://192.168.101.29:5000/"; //casa
  // const backendBaseURL = "http://192.168.101.14:5000/"; //ofcina
  const backendBaseURL = "https://rymmao-backend-6edac4889388.herokuapp.com/"; //heroku
  // const [dbPending, setDbPending] = useState(true);

  const getHeaderMenuItems = async () => {
    let url = backendBaseURL + "headermenu";
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        rymmao_api_key_content: process.env.REACT_APP_RYMMAO_API_KEY_CONTENT,
        rymmao_api_key_iv: process.env.REACT_APP_RYMMAO_API_KEY_IV,
      },
    });
    try {
      const jsonResponse = response.json();
      if (response.ok) {
        return jsonResponse;
      } else {
        console.log("Throwing error response");
        throw new Error(jsonResponse["message"]);
      }
    } catch (error) {
      console.log("Throwing error JSON convertion");
      throw new Error(error.message);
    }
  };

  const getDropDownItems = async (headerMenuId) => {
    let url = backendBaseURL + `headerdropmenu?headerMenuId=${headerMenuId}`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        rymmao_api_key_content: process.env.REACT_APP_RYMMAO_API_KEY_CONTENT,
        rymmao_api_key_iv: process.env.REACT_APP_RYMMAO_API_KEY_IV,
      },
    });
    try {
      const jsonResponse = response.json();
      if (response.ok) {
        return jsonResponse;
      } else {
        console.log("Throwing error response");
        throw new Error(jsonResponse["message"]);
      }
    } catch (error) {
      console.log("Throwing error JSON convertion");
      throw new Error(error.message);
    }
  };

  const sendContactForm = async (messageData) => {
    let url = backendBaseURL + "sendContactForm";
    // console.log(messageData);
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        rymmao_api_key_content: process.env.REACT_APP_RYMMAO_API_KEY_CONTENT,
        rymmao_api_key_iv: process.env.REACT_APP_RYMMAO_API_KEY_IV,
      },
      body: JSON.stringify(messageData),
    });
    try {
      const jsonResponse = response.json();
      if (response.ok) {
        return jsonResponse;
      } else {
        console.log("Throwing error response");
        throw new Error(jsonResponse["message"]);
      }
    } catch (error) {
      console.log("Throwing error JSON convertion");
      throw new Error(error.message);
    }
  };

  const getProductData = async (productId) => {
    let url = backendBaseURL + `product?productId=${productId}`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        rymmao_api_key_content: process.env.REACT_APP_RYMMAO_API_KEY_CONTENT,
        rymmao_api_key_iv: process.env.REACT_APP_RYMMAO_API_KEY_IV,
      },
    });
    try {
      const jsonResponse = response.json();
      if (response.ok) {
        return jsonResponse;
      } else {
        console.log("Throwing error response");
        throw new Error(jsonResponse["message"]);
      }
    } catch (error) {
      console.log("Throwing error JSON convertion");
      throw new Error(error.message);
    }
  };

  const getPrivateLabelBrands = async () => {
    let url = backendBaseURL + `privatelabelbrands`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        rymmao_api_key_content: process.env.REACT_APP_RYMMAO_API_KEY_CONTENT,
        rymmao_api_key_iv: process.env.REACT_APP_RYMMAO_API_KEY_IV,
      },
    });
    try {
      const jsonResponse = response.json();
      if (response.ok) {
        return jsonResponse;
      } else {
        console.log("Throwing error response");
        throw new Error(jsonResponse["message"]);
      }
    } catch (error) {
      console.log("Throwing error JSON convertion");
      throw new Error(error.message);
    }
  };

  return (
    <backendContext.Provider
      value={{
        getHeaderMenuItems,
        getDropDownItems,
        sendContactForm,
        getProductData,
        getPrivateLabelBrands,
      }}
    >
      {children}
    </backendContext.Provider>
  );
}
