import React from "react";

import instagramLogo from "../resources/instagram.png";
import facebookLogo from "../resources/facebook.png";

function Contacts(props) {
  const contactData = ["(02)-807533", "0995298554", "info@rymmao.com"];

  const renderContactData = contactData.map((data) => {
    return (
      <div
        style={{
          paddingRight: "15px",
          fontSize: "1rem",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {data}
      </div>
    );
  });
  return (
    <React.Fragment>
      <div
        className="top_content"
        style={{
          display: "flex",
          width: "100%",
          // border: "2px solid red",
        }}
      >
        <div
          style={{
            // border: "2px solid green",
            // width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={instagramLogo}
            alt="instagramLogo"
            width={"30px"}
            style={{
              marginLeft: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              // marginRight: "15px",
              // marginTop: "7px",
              // marginBottom: "5px",
              // border: "2px solid black",
            }}
          />
          <a
            href="https://www.facebook.com/originalcosmeticsecuador"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={facebookLogo}
              alt="facebookLogo"
              width={"25px"}
              style={{
                marginLeft: "20px",
                marginRight: "15px",
                marginTop: "7px",
                marginBottom: "7px",
                // border: "2px solid black",
              }}
            />
          </a>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
            // border: "2px solid red",
          }}
        >
          {renderContactData}
        </div>
      </div>
    </React.Fragment>
  );
}

export default Contacts;
