import React from "react";

import serviceImg1 from "../resources/service_img_1.jpeg";

function InvestigacionDesarrolloComponent() {
  return (
    <React.Fragment>
      {/* I+D */}
      <div
        className="content"
        style={{
          display: "flex",
          padding: "20px",
          paddingTop: "40px",
          paddingBottom: "10px",
          justifyContent: "center",
          alignItems: "center",
          //   borderBottom: "10px solid lightgrey",
        }}
      >
        <div style={{ paddingLeft: "80px" }}>
          <img src={serviceImg1} width={"300px"} alt="" />
        </div>
        <div
          style={{
            textAlign: "justify",
            paddingLeft: "70px",
            paddingRight: "125px",
          }}
        >
          <div>
            <p>
              En Corporación Rymmao, ofrecemos un servicio integral de
              Investigación y Desarrollo (I+D). Impulsa nuestra misión de crear
              productos cosméticos y dermocosméticos innovadores que cuiden de
              las personas y del planeta. Nuestro enfoque combina tecnología de
              vanguardia, ingredientes de origen sostenible y un profundo
              compromiso con la excelencia. Nos enfocamos en investigar
              ingredientes activos de eficacia comprobada y en crear fórmulas
              que se adapten a las necesidades específicas de nuestros clientes.
              Cada fórmula es el resultado de rigurosos estudios científicos y
              pruebas que garantizan la seguridad, eficacia y calidad de
              nuestros productos, donde no solo embellecen, sino que también
              cuidan y protegen la piel.
            </p>
          </div>
          <div
            className="subtitle"
            style={{
              //   border: "2px solid red",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            Innovamos hoy para un futuro más bello, saludable y sostenible.
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default InvestigacionDesarrolloComponent;
