import React from "react";

function MaquilaParcialService() {
  return (
    <React.Fragment>
      <div
        className="content"
        style={{
          display: "flex",
          padding: "20px",
          paddingTop: "40px",
          paddingBottom: "10px",
          justifyContent: "center",
          alignItems: "center",
          //   borderBottom: "10px solid lightgrey",
        }}
      >
        <div
          style={{
            textAlign: "justify",
            paddingLeft: "30px",
            paddingRight: "30px",
          }}
        >
          <div>
            <p>
              Perfección en cada detalle. Si cuentas con una fórmula propia,
              insumos o materiales de empaque, nosotros transformamos tu visión
              en un producto de excelencia con costos y tiempos optimizados, sin
              comprometer la calidad.
            </p>
          </div>
          <div>
            <ul>
              <li>Procesos de alta precisión,</li>
              <li>Envasado y acabado impecable,</li>
              <li>Control de calidad sin concesiones</li>
            </ul>
          </div>
          <div
            className="subtitle"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            Exclusividad. Sofisticación. Confianza.
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default MaquilaParcialService;
