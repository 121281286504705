import React from "react";
import Spinner from "react-bootstrap/Spinner";

function LoadingSpinner(props) {
  if (props.show) {
    return (
      <React.Fragment>
        <div
          className="d-flex justify-content-center align-items-center py-3"
          // style={{ border: "2px solid blue" }}
        >
          <Spinner
            animation="grow"
            variant="dark"
            size={props.size ? props.size : ""}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default LoadingSpinner;
