import React from "react";

function GMap(props) {
  return (
    <React.Fragment>
      <iframe
        title="map"
        width={"100%"}
        height={"100%"}
        // style={{ border: "0" }}
        loading="lazy"
        // allowfullscreen
        src={
          "https://www.google.com/maps/embed/v1/place?q=place_id:ChIJoZZoY4GP1ZERYU0ck910KG8&key=" +
          process.env.REACT_APP_GOOGLE_MAPS_API_KEY
        }
      ></iframe>
    </React.Fragment>
  );
}

export default GMap;
