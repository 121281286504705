import React, { useState } from "react";

import Contacts from "../components/contacts";
import Header from "../components/header";
import InvestigacionDesarrolloComponent from "../components/id_service";
import FullMaquilaService from "../components/ful_maquila_service";
import MaquilaParcialService from "../components/maquila_parcial_service";
import AsesoriaService from "../components/asesoria_service";

import idIcon from "../resources/i_d.png";
import fullMaquilaIcon from "../resources/full_maquila.png";
import maquilaParcialIcon from "../resources/maquila_parcial.png";
import asesoriaIcon from "../resources/asesoria.png";

function ServicesRoute() {
  // const iconWidth = 140;
  const [selectedService, setSelectedService] = useState(1);

  const handelServiceClick = (serviceId) => {
    setSelectedService(serviceId);
  };

  return (
    <div
      style={{
        background: "#fff",
        // border: "2px solid green"
      }}
    >
      <Contacts />
      <Header selected={1} />
      <div className="title">Servicios</div>
      <div
        className="content"
        style={{
          display: "flex",
          // border: "2px solid red",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          className="row row-cols-2 row-cols-sm-2 row-cols-md-4 row-cols-lg-4 d-flex justify-content-around "
          style={{
            // border: "2px solid green",
            width: "100%",
            alignItems: "baseline",
          }}
        >
          {/* id_icon */}
          <div
            style={{
              // border: "2px solid red",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              paddingTop: "5px",
              // cursor: "pointer",
            }}
          >
            <div
              style={{
                // border: "2px solid black",
                borderRadius: "200px",
                width: "200px",
                height: "200px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: selectedService === 1 ? "#ddb464" : "none",
                cursor: "pointer",
              }}
              onClick={() => {
                handelServiceClick(1);
              }}
            >
              <img src={idIcon} alt="Investigación y Desarrollo" width="70%" />
            </div>
            <div className="subtitle">
              Investigación <br /> & <br /> Desarrollo
            </div>
          </div>
          {/* full_maquila_icon */}
          <div
            style={{
              // border: "2px solid red",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              paddingTop: "5px",
              // cursor: "pointer",
            }}
          >
            <div
              style={{
                // border: "2px solid black",
                borderRadius: "200px",
                width: "200px",
                height: "200px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: selectedService === 2 ? "#ddb464" : "none",
                cursor: "pointer",
              }}
              onClick={() => {
                handelServiceClick(2);
              }}
            >
              <img src={fullMaquilaIcon} alt="Full Maquila" width="70%" />
            </div>
            <div className="subtitle">Full Maquila</div>
          </div>
          {/* maquila_parcial_icon */}
          <div
            style={{
              // border: "2px solid red",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              paddingTop: "5px",
              // cursor: "pointer",
            }}
          >
            <div
              style={{
                // border: "2px solid black",
                borderRadius: "200px",
                width: "200px",
                height: "200px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: selectedService === 3 ? "#ddb464" : "none",
                cursor: "pointer",
              }}
              onClick={() => {
                handelServiceClick(3);
              }}
            >
              <img src={maquilaParcialIcon} alt="Maquila Parcial" width="70%" />
            </div>
            <div className="subtitle">Maquila Parcial</div>
          </div>
          {/* asesoria_icon */}
          <div
            style={{
              // border: "2px solid red",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              paddingTop: "5px",
              // cursor: "pointer",
            }}
          >
            <div
              style={{
                // border: "2px solid black",
                borderRadius: "200px",
                width: "200px",
                height: "200px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: selectedService === 4 ? "#ddb464" : "none",
                cursor: "pointer",
              }}
              onClick={() => {
                handelServiceClick(4);
              }}
            >
              <img src={asesoriaIcon} alt="Asesoria" width="70%" />
            </div>
            <div className="subtitle">Asesoria</div>
          </div>
        </div>
      </div>
      {selectedService === 1 ? (
        <div>
          <InvestigacionDesarrolloComponent />
        </div>
      ) : (
        ""
      )}

      {selectedService === 2 ? (
        <div>
          <FullMaquilaService />
        </div>
      ) : (
        ""
      )}

      {selectedService === 3 ? (
        <div>
          <MaquilaParcialService />
        </div>
      ) : (
        ""
      )}

      {selectedService === 4 ? (
        <div>
          <AsesoriaService />
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default ServicesRoute;
