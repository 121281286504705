import "./App.css";
import HomeRoute from "./routes/homeRoute";
import ServicesRoute from "./routes/servicesRoute";
import AboutUsRoute from "./routes/aboutUsRoute";
import ContactsRoute from "./routes/contactsRoute";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import { BackendProvider } from "./context/backendContext";

import FloatingButton from "./components/floatingButton";
import DistributorsRoute from "./routes/distributorsRoute";
import ProductsRoute from "./routes/productsRoute";

function App() {
  return (
    <BrowserRouter>
      <div
        className="App"
        style={{
          background: "#fff",
          // border:"2px solid red"
        }}
      >
        <BackendProvider>
          <Routes>
            <Route exact path="/" element={<HomeRoute />} />
            <Route exact path="/services" element={<ServicesRoute />} />
            <Route exact path="/aboutus" element={<AboutUsRoute />} />
            <Route exact path="/contacts" element={<ContactsRoute />} />
            <Route exact path="/distributors" element={<DistributorsRoute />} />
            {/* <Route exact path="/" element={<UnderConstructionRoute/>}/> */}
            <Route
              exact
              path="/products/:productId"
              element={<ProductsRoute />}
            />
          </Routes>
          <FloatingButton link="https://wa.me/593995298554" />
        </BackendProvider>
      </div>
    </BrowserRouter>
  );
}

export default App;
